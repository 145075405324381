<template>
  <v-container fluid class="custom-container-details ">
    <div>
      <v-row wrap no-gutters>
        <v-col
          v-if="!userCanEdit && !loading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="tab"
            class="custom-tab-design"
            background-color="transparent"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-history"
              class="ml-2"
            >
              {{ $lang.labels.history }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class=" custom-bg pt-6">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-1 fill-height custom-bg">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters class="py-1">
                    <v-col
                      cols="2"
                      class="pr-sm-1"
                    >
                      <v-select
                        v-model="translation.language"
                        :items="availableLanguages"
                        :label="$lang.labels.language"
                        outlined
                        dense
                        required
                        :readonly="!userCanEdit"
                        class="required-asterisk"
                        :rules="[v => !!v || $lang.labels.required]"
                        data-cy="translation-language"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="10"
                      class="pl-sm-1"
                    >
                      <v-text-field
                        v-model="translation.name"
                        dense
                        :label="$lang.labels.name"
                        required
                        outlined
                        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, false) || $lang.errors.variableJavaWrong, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                        class="required-asterisk copy-name-icon"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit"
                        data-cy="translation-name"
                        append-icon="mdi-vector-combine"
                        @click:append="copyName()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row wrap no-gutters >
                    <v-col
                      cols="12"
                    >
                      <v-textarea
                        v-model="translation.text"
                        outlined
                        dense
                        rows="8"
                        auto-grow
                        :label="$lang.labels.body"
                        required
                        class="required-asterisk"
                        :rules="[v => !!v || $lang.labels.required]"
                        :readonly="!userCanEdit"
                        data-cy="translation-text"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider class="mb-4" />
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          outlined
                          color="primary"

                          data-cy="translation-back"
                          :to="{ name: 'translations', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          outlined
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="translation.name"
                          :title="$lang.actions.delete"
                          type="translation"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'validation-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteTranslationFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'translations' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-btn
                        outlined
                        :disabled="!valid || lock || !userCanEdit || !isEdit"
                        color="primary"
                        class="mr-2 color-accent-text"
                        @click="copyCreateResource(translation, 'translationCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-content-copy
                        </v-icon>
                        {{ $lang.actions.copyCreate }}
                      </v-btn>
                      <v-btn
                        :disabled="!isFormValid"
                        color="primary"

                        data-cy="translation-submit"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="py-1 fill-height custom-bg">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreTranslation($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
        <yes-no-modal :title="$lang.actions.delete" :action-text="$lang.header.sureDeleteTranslation" @submit="deleteTranslation" @closeDialog="tempItem = null; yesNoShow = false"></yes-no-modal>
      </v-dialog>
      <multi-errors-snackbar
        :show="showSnackErrors"
        :errors="errorsForSnackbar"
        @close="showSnackErrors = false; errorsForSnackbar = []"
      />
    </div>
  </v-container>
</template>

<script>
import {
  createTranslationUsingPOST as createTranslation,
  updateTranslationUsingPUT as updateTranslation,
  deleteTranslationUsingDELETE as deleteTranslation,
  getTranslationUsingGET as getTranslation,
  getHistoriesUsingGET as getHistories
} from '@/utils/api'
import YesNoModal from '@/components/ui/modals/YesNoModal'
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import copy from 'copy-to-clipboard'
import { mapActions } from 'vuex'
import { copyCreateResource, getRolesWithoutAuth } from '@/utils/helpers'
import { bus } from '@/main'

export default {
  components: {
    ActionButtonWithConfirmation,
    MultiErrorsSnackbar,
    YesNoModal,
    Table,
    UserRolesSelect
  },
  data() {
    return {
      tab: 0,
      yesNoShow: false,
      err: '',
      success: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      valid: false,
      isEdit: false,
      loading: true,
      lock: false,
      translation: {
        createdOn: '',
        id: '',
        modifiedOn: '',
        name: '',
        language: '',
        status: '',
        text: '',
        roles: []
      },
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      translations: [],
      isSuperUser: false,
      history: { items: [], meta: {} },
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.translation.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.translation.modifiedOn)}, ID: ${this.translation.id}`
    },
    isFormValid() {
      return this.valid && !this.lock && this.userCanEdit
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    },
    tab() {
      if (this.tab === 2) {
        this.fetchHistory()
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'translationEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'GLOBAL_TRANSLATION_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true

          getTranslation({ id: this.$route.params.id })
            .then((res) => {
              this.translation = res.data.data

              if (this.translation.roles) {

                this.editRolesIds = this.translation.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
                this.useRolesIds = this.translation.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
                this.viewRolesIds = this.translation.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

                this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
              } else {
                this.userCanEdit = true
              }

              this.loading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else if (this.$route.params.restore) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.translation = this.$route.params.restore
          this.translation.id = 0
          this.loading = false

        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.translation = this.$route.params.copy
            this.loading = false

          } else {
            this.loading = false
          }
        }
      })
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),
    copyCreateResource,
    copyName() {
      copy(this.translation.name)
      this.addSnackbar({
        message: 'Copied',
        timeout: 3000,
        color: 'success'
      })

    },
    deleteTranslationFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteTranslation({ translationId: this.translation.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    fetchHistory(options) {
      if (!this.translation.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.translation.id
      obj.resourceType = 'GLOBAL_TRANSLATION'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    restoreTranslation(data) {
      this.translation = data

      if (this.translation && this.translation.roles) {
        this.editRolesIds = this.translation.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.translation.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.translation.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async submit() {

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.addSnackbar({
          message: this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.lock = true

      let res = null

      const { id } = this.translation

      if (!this.isEdit) {
        delete this.translation.id
      }
      delete this.translation.createdOn
      delete this.translation.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      this.translation.roles = tempRoles

      try {

        res = this.isEdit ? await updateTranslation({ translationId: id, body: this.translation }) : await createTranslation({ body: this.translation })

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.translationUpdate : this.$lang.errors.translationCreate)

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.translationUpdated : this.$lang.success.translationCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })

        this.lock = false

        if (!this.isEdit) {
          this.$router.push({
            name: 'translationEdit',
            params: { id: res.data.data.id }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>
